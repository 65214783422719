<template>
  <v-main>
    <v-container
      fill-height
      fluid>
      <v-layout
        align-center
        justify-center>
        <v-flex
          xs12
          sm8
          md4>
          <v-card
            color="biscuit lighten-2"
            class="elevation-12">
            <v-toolbar
              color="biscuit"
              class="justify-center">
              <v-spacer />
              <v-toolbar-title>Admin Panel</v-toolbar-title>
              <v-spacer />
            </v-toolbar>

            <!-- <v-card-title class="mb-2 justify-center" style="background-color: #d9c7A3">
            <v-spacer />
            Admin Panel
            <v-spacer /> 
            </v-card-title> -->

            <v-card-text>
              <v-form >
                <v-text-field
                  ref="username"
                  v-model="username"
                  :rules="[() => !!username || 'This field is required']"
                  prepend-icon="mdi-account"
                  label="Login"
                  placeholder="user@example.com"
                  required
                />
                <v-text-field
                  ref="password"
                  v-model="password"
                  :rules="[() => !!password || 'This field is required']"
                  :append-icon="showPassword ? 'mdi-eye-off' : 'mdi-eye'"
                  :type="showPassword ? 'text' : 'password'"
                  prepend-icon="mdi-lock"
                  label="Password"
                  placeholder="*********"
                  counter
                  required
                  @keydown.enter="login"
                  @click:append="showPassword = !showPassword"
                />
              </v-form>
            </v-card-text>
            <!-- <v-divider class="mt-5"/> -->
              <v-card-text class="align-right justify-right text-caption" :to="{name: 'forgotpassword'}">
                <router-link :to="{name: 'forgotpassword'}" custom v-slot="{navigate}">
                  <span @click="navigate" role="link" style="cursor: pointer;">Forgot password?</span>
                </router-link>
              </v-card-text>
            <v-divider class="mt-5"/>
            <v-card-actions>
              <!-- <v-spacer/> -->
              <v-btn
                block
                align-center
                justify-center
                color="guacamole"
                :disabled="!isFormValid"
                @click="login"><span v-if="!loading">Login</span>
                <v-progress-circular indeterminate color="biscuit" v-else />
              </v-btn>
              
            </v-card-actions>
            <v-snackbar
              v-model="snackbar"
              :color="color"
              :timeout="timeout"
              dark
            >
              {{ errormsg }}
              <v-btn
                dark
                text
                align-right
                @click="snackbar = false"
              >
                Close
              </v-btn>
            </v-snackbar>
          </v-card>
        </v-flex>
      </v-layout>

    </v-container>
  </v-main>
</template>

<script>
import User from '../models/user';
import { get, call } from 'vuex-pathify'

export default {
  data: function () {
    return {
      username: '',
      password: '',
      user: new User('', ''),
      errorMessages: 'Incorrect login info',
      snackbar: false,
      timeout: 5000,
      color: 'error',
      showPassword: false,
      showerror: false,
    }
  },

  computed: {
    loading: get('newauth/loading'),
    loggedIn: get('newauth/loggedIn'),
    logInError: get('newauth/logInError'),
    errormsg: get('newauth/errormsg'),
    isFormValid() {
      return this.username !== '' && this.password !== '';
    }
  },
  created() {
    if (this.loggedIn) {
      console.log("LoginForm.created, this.loggeIn is true")
      this.$router.push({name: 'Dashboard View'})
    }
  },
  // Sends action to Vuex that will log you in and redirect to the dash otherwise, error
  methods: {
    dologin: call('newauth/logIn'),
    async login () {
      this.user.username = this.username
      this.user.password = this.password

      // Do the login and wait for it to make sure we get the proper
      // status of the operation.
      await this.dologin(this.user)
      if (this.logInError === true) {
        this.showSnackbar()
      }
    },
    showSnackbar() {
      this.snackbar = true
      setTimeout(() => {this.snackbar=false}, this.timeout)
    }
  },
  metaInfo () {
    return {
      title: 'Super Secret | Login'
    }
  }
}
</script>
